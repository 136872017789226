<template>
  <div class="row m-0 align-items-center">
    <div class="col-12 px-0 pt-2">
      <div class="row m-0 justify-content-center">
        <TheSteps :page="page" :wizardSteps="wizardSteps" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'MobileSteps',
  props: ['page', 'wizardSteps'],
  components: {
    TheSteps: defineAsyncComponent(() => import('./TheSteps.vue'))
  }
}
</script>
